import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: October 28, 2024</p>
      
      <p>
        This Privacy Policy explains our policies and procedures on the collection, use, and disclosure of your 
        information when you use our Service. It also informs you about your privacy rights and how the law protects 
        you. By using this Service, you agree to the collection and use of information in accordance with this 
        Privacy Policy.
      </p>
      
      <section>
        <h2>Definitions</h2>
        <p><strong>Account:</strong> A unique account created for you to access our Service.</p>
        <p><strong>Company:</strong> BestPick (referred to as "we," "us," or "our").</p>
        <p><strong>Country:</strong> Thailand.</p>
        <p><strong>Device:</strong> Any internet-connected device (e.g., smartphone, tablet, or computer) that can access our Service.</p>
        <p><strong>Personal Data:</strong> Information that identifies or relates to an identifiable individual (e.g., name, email).</p>
        <p><strong>Service:</strong> Our mobile or web application.</p>
        <p><strong>Service Provider:</strong> External companies or individuals that provide services to help deliver our Service (e.g., hosting, analytics).</p>
        <p><strong>Third-party Social Media Service:</strong> External social media networks users may log in through (e.g., Facebook, Google).</p>
        <p><strong>Usage Data:</strong> Data collected automatically (e.g., app usage duration, page views).</p>
        <p><strong>You:</strong> The individual using our Service.</p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          We collect different types of information to improve the user experience and deliver our Service. The types of data collected include:
        </p>
        <ul>
          <li><strong>Personal Data:</strong> Information you provide directly, such as name, email, and contact details.</li>
          <li><strong>Usage Data:</strong> Information about how you access and use the Service, such as IP address, browser type, and pages viewed.</li>
          <li><strong>Interaction Data:</strong> Data on interactions within the Service, like buttons clicked and actions performed.</li>
          <li><strong>Device Data:</strong> Technical details, such as device type and operating system, to ensure compatibility and optimize performance.</li>
          <li><strong>Social Media Service Data:</strong> Information shared through external social media if you log in using such services.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We use the collected information to:
        </p>
        <ul>
          <li>Provide and maintain our Service, including creating and managing user accounts.</li>
          <li>Personalize your experience, based on preferences and past interactions.</li>
          <li>Communicate updates, offers, or security alerts.</li>
          <li>Fulfill contractual obligations or process transactions.</li>
          <li>Analyze usage to improve the quality and features of our Service.</li>
        </ul>
      </section>

      <section>
        <h2>Sharing Your Information</h2>
        <p>
          We only share your information in specific situations, including:
        </p>
        <ul>
          <li>With <strong>Service Providers</strong> who assist in delivering our Service (e.g., cloud storage, payment processing).</li>
          <li>In <strong>business transfers</strong> in the event of a merger, acquisition, or sale of assets.</li>
          <li>With <strong>affiliates and business partners</strong> for marketing, analytics, or service improvements (with your consent where required).</li>
          <li>With <strong>your consent</strong> if you have explicitly agreed to share your data with a specific third party.</li>
        </ul>
      </section>

      <section>
        <h2>Retention of Your Personal Data</h2>
        <p>
          We retain your data only for as long as needed to provide our Service or meet legal obligations. For instance:
        </p>
        <ul>
          <li>Account information is kept until your account is deleted or as required by law.</li>
          <li>Usage Data may be retained for analytics purposes, typically for a shorter period unless used for security or legal purposes.</li>
        </ul>
      </section>

      <section>
        <h2>Security of Your Personal Data</h2>
        <p>
          We take reasonable security measures to protect your data, including encryption and access controls. However, no method of transmission is completely secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section>
        <h2>Children’s Privacy</h2>
        <p>
          Our Service is not intended for children under 13. We do not knowingly collect data from children under 13. If we become aware that we have collected data from a child under 13, we will delete it.
        </p>
      </section>

      <section>
        <h2>Links to Other Websites</h2>
        <p>
          Our Service may link to external websites, which are not operated by us. We advise you to review the privacy policies of these sites.
        </p>
      </section>

      <section>
        <h2>Your Rights Regarding Your Personal Data</h2>
        <p>
          You have rights under data protection laws, including the right to:
        </p>
        <ul>
          <li>Access, correct, or delete your personal data.</li>
          <li>Request restriction or object to processing.</li>
          <li>Withdraw consent at any time (this may limit certain functionalities).</li>
        </ul>
        <p>
          To exercise these rights, contact us using the information provided below.
        </p>
      </section>

      <section>
        <h2>Consent and Withdrawal of Consent</h2>
        <p>
          By using our Service, you consent to this Privacy Policy. You may withdraw consent by contacting us. Note that this may limit certain Service functionalities.
        </p>
      </section>

      <section>
        <h2>Notification of Changes</h2>
        <p>
          We will notify you of significant changes to this Privacy Policy via email or other appropriate means.
        </p>
      </section>

      <section>
        <h2>Contact Information</h2>
        <p>
          If you have questions or concerns about this Privacy Policy, please contact us at:<br />
          Email: <a href="mailto:bestpick050@gmail.com">bestpick050@gmail.com</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
